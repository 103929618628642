import React from "react"
import styled from "styled-components"
import { withTheme } from "@material-ui/core"
import SEO from "../components/seo"
import _Container from "../components/container"
import NavBar from "../components/navbar"
import Layout from "../components/layout"
import ComingSoon from "../components/coming-soon"

const PrivacyPolicyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <NavBar />
    <Container>
      <h1>Privacy Policy</h1>
      <p>
{`当ウェブサイトは、STAKE TECHNOLOGIES PTE. LTD.（以下「当社」）の事業内容等を紹介するサイトです。

個人情報保護方針
当社は、以下のとおり個人情報保護方針(以下「本ポリシー」といいます。)を定め、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。

お客様情報の管理
1.本ポリシーにおいて、「お客様情報」とは、個人情報（個人情報保護法第２条第１項により定義された「個人情報」をいい、以下同様とします。）、お客様の入力情報、お客様の通信サービス上の行動履歴その他お客様の情報端末において生成又は蓄積された情報、及び当社と提携する企業による提携サービスの提供に伴い取得したお客様に関する情報であって、本ポリシーに基づき当社が収集するものをいいます。

2.当社は、お客様情報を正確かつ最新の状態に保ち、お客様情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施しお客様情報の厳重な管理を行ないます。

取得するお客様情報
当社は、以下に定めるとおり、お客様情報を取得することがあります。お客様情報には、以下の情報が含まれます。
1.氏名
2.所属企業名等
3.所属部署名
4.役職
5.メールアドレス
6.端末情報
7.ログ情報及び履歴情報（IPアドレス、操作履歴等）
8.Cookie等

利用目的
1.本ウェブサイトでは、お客様からのお問い合わせ時に、「取得するお客様情報」にて列挙した情報をご登録いただく場合がございます。お客様は、本ポリシーに基づくお客様情報の収集及び取扱いに同意の上、当社へお問い合わせください。お客様は、当該同意をいつでも撤回することができますが、同意の撤回は、撤回の前のお客様情報の取扱いの効力に影響を及ぼすものではありません。また、当社は１６歳未満の方からのお問い合わせを意図しておらず、当社は、１６歳未満の方からお客様情報を取得することを意図しておりません。

2.前項に基づき収集したお客様情報は、当社からのご連絡、業務のご案内又はご質問に対する回答として、当社からの電子メール等を用いた、お問い合わせに対する回答や当社資料の送付に利用いたしますが、それ以外の目的では利用いたしません。

3.当社は、前条の利用目的を、変更前の利用目的と相当の関連性を有すると合理的に認められる範囲内において変更することがあり、かかる変更をする場合には、当社が別途定める方法により、お客様に対して、利用目的を変更する旨及びかかる変更に関連する情報を通知します

情報収集モジュール
1.本サービスには、お客様情報を解析するため、以下の情報収集モジュールが組み込まれています。これに伴い、当社は、以下の情報収集モジュール提供者へお客様情報の提供を行います。これら情報収集モジュールは、Cookie等を利用し、個人を特定する個人情報を含まないお客様情報を収集し、収集された情報は第２号記載の情報収集モジュール提供者のプライバシーポリシーその他の規定に基づき管理されます（詳細については、各情報収集モジュール提供者のプライバシーポリシー等をご参照ください。）。
1.情報収集モジュールの名称
①Google Analytics
②Crittercism
③Flurry
④PartyTrack
⑤Facebook SDK
⑥Crashlytics
2.情報収集モジュールの提供者
①Google, Inc.
②Crittercism, Inc. 
③Flurry Inc.
④アドウェイズ株式会社
⑤Facebook, Inc.
⑥Crashlytics, Inc

第三者への開示・提供の禁止
1.当社は、お客様よりお預かりしたお客様情報を適切に管理し、次のいずれかに該当する場合を除き、お客様情報を第三者に開示いたしません。
・お客様の同意がある場合
・お客様が希望されるサービスを行なうため必要な範囲において当社が業務を委託する業者に対して開示する場合
・情報収集モジュール提供者等へお客様情報が提供される場合
・合併その他の事由による事業の承継に伴ってお客様情報が提供される場合
・法令に基づき開示することが必要である場合

2.当社は、欧州委員会が適切なレベルの個人データの保護が保障されていると判断した国又は国際機関以外の第三国又は国際機関にお客様情報を移転することがあります。そのような場合には、適切な保障措置を講ずるなどの法律や規制によって要求される手段を講じ、移転に伴うリスクに関する情報を提供した後で、お客様から明示的な同意を得るものとします。

安全対策
当社は、お客様情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。

個人情報に関するご本人の照会・保管
1.お客様から提供された個人情報について、個人情報保護法その他の法令又は規則の定めに基づきお客様が書面による開示、訂正、追加、削除若しくは取扱いの制限の要望、又は処理の異議申立権若しくはデータポータビリティ権の行使（以下、総称して「開示等」といいます。）をする場合は、申請書に本人確認（代理人による申請の場合、適正な代理人であることの確認）のために必要な書類を同封のうえ、郵送によりご請求ください。但し、個人情報保護法その他の法令等により、当社が開示等の義務を負わない場合は、この限りではなく、開示等に応じられない場合にはその旨の通知をいたします。

2.前項にいずれかに基づく申出があった場合において、本人確認がなされたときは、当社は、原則としてお申出から１ヶ月以内に、合理的な範囲で個人情報の開示等をします。但し、個人情報保護法その他の法令又は規則により、当社が開示等の義務を負わない場合、正当な理由なく同内容の請求が何度も繰り返される場合その他これに類する場合はこの限りではなく、開示等に応じられない場合には、その旨の通知をいたします。

3.第１項に基づく申出があった場合において、本人確認がなされたときは、当社は、合理的な範囲で遅滞なく調査を行い、原則としてお申出から１ヶ月以内に、その結果に基づき個人情報の開示等をします。但し、個人情報保護法その他の法令により、当社がこれらの義務を負わない場合、正当な理由なく同内容の請求が何度も繰り返される場合その他これに類する場合はこの限りではありません。

4.お客様は、当社による個人情報の利用について、監督官庁に対して苦情を申し立てる権利があります。

5.当社は、取扱いの制限を行った個人情報について、取扱いの制限の解除を行う場合、事前にお客様本人に対して通知を行います。

6.当社は、利用目的において必要なくなった場合、又は本ポリシーに定めていない利用目的のために個人情報を保存しません。個人情報は、当社が定めた保存期間が経過した場合、又は本ポリシーに規定された目的その他の目的が法律又は規制により、当社がそのような個人情報を保存することを必要とする場合を除き、破棄されます。

法令、規範の遵守と見直し

1.当社は、代表取締役社長を管理責任者とし、当社が保有するお客様情報に関して適用されるシンガポールの法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。

免責
1.当社は、以下の場合には何らの責任を負いません。
1.お客様ご本人が別の手段を用いて第三者にお客様情報を明らかにした場合
2.お客様自らお問い合わせに入力した情報により、個人を識別し得る状態に至った場合

2.当ウェブサイトは、当社が管理するサービス以外のサービスへのリンクを含む場合があり、これら外部サービスにおける内容やお客様情報の保護については、当社は一切責任を負いません。

本ポリシーの変更
当社は、お客様情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本ポリシーを以下の場合に当社の裁量により変更できるものとします。
1.利用規約の変更が、契約者の一般の利益に適合するとき
2.利用規約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき
当社は、前項に基づき本ポリシーを変更する場合には、当社は、変更後の本ポリシーの効力発生日の２週間前までに、当社ウェブサイトにおいて掲示するものとします。


お問い合せ
当社のお客様情報の取扱に関するお問い合せは下記までご連絡ください。
`}
      </p>
    </Container>
  </Layout>
)

const Container = withTheme(styled(_Container)`
  h1 {
    text-align: center;
    font: 60px/81px TT Commons;
    font-weight: Bold;
    letter-spacing: 0px;
    color: #1a1a1a;
    opacity: 1;
    margin: 0 auto 77px;
  }
  h2 {
    text-align: left;
    font: 700 16px/30px Noto Sans JP;
    letter-spacing: 0px;
    color: #1a1a1a;
    opacity: 1;
    margin: 0 auto 0;
  }
  p {
    text-align: left;
    font: 16px/30px Noto Sans JP;
    letter-spacing: 0px;
    color: #7b7b7b;
    opacity: 1;
    margin: 0 auto 50px;
  }
  & h1 {
    text-align: center;
    margin: 110px auto 0px;
    font: 60px/81px TT Commons;
    letter-spacing: 0px;
    font-weight: Bold;
  }
  & h2 {
    margin: 54px auto 0px;
    font: 16px/30px Noto Sans JP;
    font-weight: 600;
  }
  & h3 {
    margin: 59px auto 0px;
    font: 700 35px/52px Noto Sans JP;
  }
  & p {
    white-space: pre-wrap;
    margin: 38px auto 0px 0px;
    color: #7b7b7b;
    font: 16px/30px Noto Sans JP;
  }
  ${props => props.theme.breakpoints.down("xs")} {
    & h1 {
      margin-top: 71px;
      font-size: 40px;
      line-height: 54px;
    }
    & h2 {
      margin-top: 21px;
      font-size: 26px;
      line-height: 35px;
    }
    & h3 {
      margin-top: 20px;
      font-size: 24px;
      line-height: 36px;
    }
    & p {
      margin-top: 20px;
      font-size: 15px;
    }
  }
`)

export default PrivacyPolicyPage
